import "$styles/index.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

import $ from "jquery"

const coords = [
  ["48.8582183", "11.7358404", "14.00"],
  ["48.4276481", "12.0582847", "18.00"],
  ["52.9319413", "08.0467129", "14.79"],
  ["50.4025802", "11.2297812", "18.00"],
  ["54.4248635", "10.3866562", "15.55"]
]

function loc(i) {
  const loc = $("#location")
  const [x, y, z] = coords[i]
  loc.empty()
  $("<a/>").attr({href: `https://www.google.at/maps/@${x},${y},${z}z`}).text("✺").appendTo(loc)
  $("<span/>").text(`${x} / ${y}`).appendTo(loc)
  $("<br/>").appendTo(loc)
}

document.addEventListener("DOMContentLoaded", () => {
  let i = Math.floor(Math.random() * coords.length)
  loc(i)
  setInterval(() => {
    i = (i + 1) % coords.length
    loc(i)
  }, 5000)
})
